import { HttpStatusCode } from '@solidjs/start';
import dayjs from '@troon/dayjs';
import {
	ArticleTile,
	BreadcrumbItem,
	Breadcrumbs,
	Button,
	Container,
	Heading,
	HorizontalRule,
	Link,
	Page,
	Pagination,
	Section,
} from '@troon/ui';
import { createAsync, Navigate, useLocation, useParams } from '@solidjs/router';
import { For, Match, Show, Suspense, Switch } from 'solid-js';
import { Title } from '@solidjs/meta';
import { IconArrowLeftMd } from '@troon/icons/arrow-left-md';
import { createContentfulListRequest } from '../../content/[model]/_client';

const perPage = 9;

export default function TheTroonInsiderPaginated() {
	const params = useParams<{ page?: string }>();
	const loc = useLocation();
	const articles = createAsync(
		async () =>
			getArticles({
				'fields.articleType': 'Shot Scope Hub',
				skip: (parseInt(params.page ?? '1', 10) - 1) * perPage,
				limit: perPage,
			}),
		{ deferStream: true },
	);

	return (
		<Switch>
			<Match when={params.page === '1'}>
				<Navigate href="/insider" />
			</Match>
			<Match when>
				<Container class="py-4">
					<Breadcrumbs>
						<BreadcrumbItem href="/insider">The Troon Insider</BreadcrumbItem>
						<BreadcrumbItem href={loc.pathname}>Page {params.page}</BreadcrumbItem>
					</Breadcrumbs>
				</Container>
				<Container>
					<Page>
						<Title>
							The Troon Insider<Show when={params.page}>{(page) => ` | Page ${page()}`}</Show>
						</Title>
						<Section>
							<Heading as="h1">The Troon Insider • Page {params.page}</Heading>
							<div class="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
								<Suspense>
									<For
										each={articles()?.items}
										fallback={
											<div class="flex flex-col gap-8">
												<HttpStatusCode code={404} />
												<p>No articles found.</p>
												<Button class="w-fit" as={Link} href="/insider">
													<IconArrowLeftMd />
													Back to The Troon Insider
												</Button>
											</div>
										}
									>
										{(release, i) => {
											const image = release.fields.heroImage?.fields;
											return (
												<ArticleTile
													hero={
														image?.file
															? {
																	src: image?.file?.url,
																	alt: image?.description ?? image?.title ?? '',
																	preload: i() < 3,
																	loading: i() < 6 ? 'eager' : 'lazy',
																}
															: undefined
													}
													url={`/insider/${release.fields.slug}`}
													title={release.fields.title}
													date={dayjs(release.fields.publishDate, 'America/Phoenix').toDate()}
												/>
											);
										}}
									</For>
								</Suspense>
							</div>
						</Section>

						<Suspense>
							<Show when={articles()?.items?.length}>
								<Show when={articles()}>
									{(data) => (
										<>
											<HorizontalRule />
											<Pagination
												currentPage={parseInt(params.page ?? '1', 10)}
												lastPage={Math.ceil(data()!.total / perPage)}
												url={(page) => `/insider/page/${page}`}
												nextTitle="Older"
												prevTitle="Newer"
											/>
										</>
									)}
								</Show>
							</Show>
						</Suspense>
					</Page>
				</Container>
			</Match>
		</Switch>
	);
}

const getArticles = createContentfulListRequest('articles');
